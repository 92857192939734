<template>
  <fems-table-ssr
    :columns="columns"
    :rows="rows"
    :total-records="totalRecords"
    :refresh-grid="refreshGrid"
    :on-column-filter-server="onColumnFilterServer"
    :server-props-perams="serverParams"
    :per-page="serverParams.perPage"
    :current-page="serverParams.page"
  >
    <!-- Custom column for AL -->
    <template
      slot="sl"
      slot-scope="props"
    >
      <span v-if="props.column.field === 'sl'">
        {{ ++props.index }}
      </span>
    </template>

    <!-- Custom column for approved Status -->
    <template
      slot="approved"
      slot-scope="props"
    >
      <span
        v-if="props.column.field === 'approved'"
        :class="props.row.is_approved===1 ? 'text-success' : props.row.is_approved===2 ? 'text-danger' : 'text-info'"
      >
        {{ props.row.approved_status }}
      </span>
    </template>
    <!-- Custom column for approved Status -->
    <template
      slot="revised"
      slot-scope="props"
    >
      <span
        v-if="props.column.field === 'revised'"
        :class="props.row.is_revised===1 ? 'text-danger' : 'text-success'"
      >
        {{ props.row.revised_status }}
      </span>
    </template>

    <!-- Custom column for action -->
    <template
      slot="action"
      slot-scope="props"
    >
      <span v-if="props.column.field === 'action'">
        <span class="d-flex">
          <b-button
            v-if="props.row.is_approved === 2 || props.row.is_approved === 0"
            class="btn-sm mr-1 px-1"
            variant="outline-info"
            @click="onApproveRow(props.row.id)"
          >
            Approve
          </b-button>
          <b-button
            v-if="props.row.is_approved === 1 || props.row.is_approved === 0"
            class="btn-sm mr-1 px-1"
            variant="outline-danger"
            @click="onDeclineRow(props.row.id)"
          >
            Decline
          </b-button>
          <b-button
            class="btn-sm mr-1 px-1"
            variant="outline-warning"
            @click="onDetailsRow(props.row.id)"
          >
            Details
          </b-button>
          <b-button
            v-if="props.row.is_approved === 2 || props.row.is_approved === 0"
            class="btn-sm px-1"
            variant="outline-danger"
            @click="onDeleteRow(props.row.id)"
          >
            Delete
          </b-button>
        </span>
      </span>
    </template>
  </fems-table-ssr>
</template>

<script>
import FemsTableSsr from '@/views/components/FemsTable/FemsTableSsr.vue'
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    FemsTableSsr,
    BButton,
  },
  props: {
    rows: Array,
    totalRecords: Number,
    onApproveRow: Function,
    onDeclineRow: Function,
    onDetailsRow: Function,
    onDeleteRow: Function,
    refreshGrid: Function,
    concessionFilterOptions: Array,
    onColumnFilterServer: Function,
    serverParams: Object,
    classList: Array,
    campusList: Array,
    sectionList: Array,
  },
  data() {
    return {
      selectedModal: null,
      dir: false,
      columns: [
        {
          label: 'SL',
          field: 'sl',
          sortable: false,
        },
        {
          label: 'Student ID',
          field: 'student_id',
          width: '200px',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Student ID',
            filterFn: this.onColumnFilter,
          },
          sortable: true,
        },
        {
          label: 'Student Name',
          field: 'student_name',
          width: '200px',

          filterOptions: {
            enabled: true,
            filterFn: this.columnFilterFn,
            placeholder: 'Student Name',
          },
          sortable: true,
        },
        {
          label: 'Concession Type',
          field: 'concession_type',
          width: '200px',
          filterOptions: {
            enabled: true,
            filterFn: this.concessionTypeFilter,
            placeholder: 'Search Concession Type',
            filterDropdownItems: this.concessionFilterOptions,
          },
          sortable: true,
        },
        {
          label: 'Campus',
          field: 'campus_name',
          width: '150px',

          filterOptions: {
            enabled: true,
            filterFn: this.columnFilterFn,
            placeholder: 'Search Campus ',
            filterDropdownItems: this.campusList,

          },
          sortable: true,
        },
        {
          label: 'Class ',
          field: 'class_name',
          width: '150px',
          filterOptions: {
            enabled: true,
            filterFn: this.columnFilterFn,
            placeholder: 'Search Class Name',
            filterDropdownItems: this.classList,
          },
          sortable: true,
        },
        {
          label: 'Roll ',
          field: 'roll',
          width: '150px',
          filterOptions: {
            enabled: true,
            filterFn: this.onColumnFilter,
            placeholder: 'Search Roll',
          },
          sortable: true,
        },
        {
          label: 'Section',
          field: 'section_name',
          width: '150px',
          filterOptions: {
            enabled: true,
            filterFn: this.columnFilterFn,
            placeholder: 'Search Section',
            filterDropdownItems: this.sectionList,
          },
          sortable: true,
        },
        {
          label: 'Amount',
          field: 'amount',
          filterOptions: {
            enabled: true,
            filterFn: this.onColumnFilter,
            placeholder: 'Search Amount',
          },
          sortable: true,
        },
        {
          label: 'Approved',
          field: 'approved',
          width: '150px',
          filterOptions: {
            enabled: true,
            filterFn: this.columnFilterFn,
            placeholder: 'Search Approved',
            filterDropdownItems: [
              { text: 'Approved', value: 1 },
              { text: 'Decline', value: 2 },
              { text: 'Pending', value: 0 },
            ],
          },
          sortable: true,
        },
        {
          label: 'Revised',
          field: 'revised',
          width: '150px',
          filterOptions: {
            enabled: true,
            filterFn: this.onRevisedFilter,
            placeholder: 'Search Revised',
            filterDropdownItems: [
              { text: 'Revised', value: 1 },
              { text: 'Not Revised', value: 0 },
            ],
          },
          sortable: true,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],

      searchTerm: '',
    }
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    onColumnFilter(data, filterString) {
      if (typeof data === 'number') {
        return data && data.toString().includes(filterString.toLowerCase())
      }
      return data && data.toLowerCase().includes(filterString.toLowerCase())
    },
  },
}
</script>
