<template>
  <!-- table -->
  <vue-good-table
    :columns="columns"
    :rows="rows"
    :rtl="direction"
    theme="my-theme"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <!-- Column: SL -->
      <span v-if="props.column.field === 'sl'">{{ ++props.index }}</span>
      <span v-if="props.column.field === 'is_active'">
        <span
          v-if="props.row.is_approved===1"
          class="text-success"
        >Approved</span>
        <span
          v-else-if="props.row.is_approved===2"
          class="text-danger"
        >Dicline</span>
        <span
          v-else-if="props.row.is_approved===0"
          class="text-warning"
        >Pending</span>
      </span>

      <!-- Column: Common -->
      <span>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>

  </vue-good-table>
</template>

<script>
import {
  VBModal,
  // BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import store from '@/store/index'

// import flatPickr from 'vue-flatpickr-component'
// import FemsToastrService from '@/@service/utils/FemsToastr.service'

export default {
  components: {
    VueGoodTable,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    rows: Array,
  },
  data() {
    return {
      selectedModal: null,
      dir: false,
      columns: [
        {
          label: 'SL',
          field: 'sl',
          sortable: false,
        },
        {
          label: 'Concession Type',
          field: 'concession_type',
          width: '200px',
          sortable: false,
        },
        {
          label: 'Amount',
          field: 'amount',
          sortable: false,
        },
        {
          label: 'Section',
          field: 'section_name',
          width: '150px',
          sortable: false,
        },
        {
          label: 'Active',
          field: 'is_active',
          width: '150px',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
  },
  created() {
    // this.getCoaGridData()
  },
  methods: {
    numberToStringFilterFn(data, filterString) {
      return data.toString().includes(filterString)
    },
    columnFilterFn(data, filterString) {
      return (data && data
        && data.toLowerCase().includes(filterString.toLowerCase()))
    },
    concessionTypeFilter(data, filterString) {
      return (data && data.toLowerCase().includes(filterString.toLowerCase()))
    },
    boolFilterFn(data, filterString) {
      return data === Number(filterString)
    },
  },
}
</script>
