<template>
  <b-card title="">
    <b-modal
      id="student-concession-modal"
      title="Student Concession"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      size="xl"
      hide-footer
    >
      <b-row>
        <b-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
        >
          <ConcessionForm
            :concession-types="concessionTypes"
            :is-loading="isLoading"
            :update-id="updateId"
            :save-student-concession="saveStudentConcession"
            :on-student-other-concession="onStudentOtherConcession"
            :update-data="updateData"
            :is-submitting="isSubmitting"
          />
        </b-col>
        <b-col
          cols="12"
          sm="12"
          md="8"
          lg="8"
        >
          <h2>Student Details</h2>
          <!-- {{ studentInfo }} -->
          <table
            class="table"
          >
            <tr>
              <td>
                <span class="m-left-text">Student ID</span>
              </td>
              <td>
                <b class="m-left-text"> {{ studentInfo.student_id }}</b>
              </td>
              <td>
                <span class="m-left-text">Student Name</span>
              </td>
              <td colspan="3">
                <b class="m-left-text"> {{ studentInfo.full_name }}</b>
              </td>
            </tr>
            <tr>
              <td>
                <span class="m-left-text">Roll</span>
              </td>
              <td>
                <b class="m-left-text"> {{ studentInfo.roll || "N/A" }}</b>
              </td>
              <td>
                <span class="m-left-text">Class</span>
              </td>
              <td>
                <b class="m-left-text">
                  {{ studentInfo.class_name || "N/A" }}</b>
              </td>
              <td>
                <span class="m-left-text">Section</span>
              </td>
              <td>
                <b class="m-left-text"> {{ studentInfo.section_name }}</b>
              </td>
            </tr>
          </table>
          <div :v-if="otherConcessionRows.lenght>0">
            <h2>Active List</h2>
            <active-list-table :rows="otherConcessionRows" />
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <div class="d-flex justify-content-between">
      <p class="card-title ">
        Student Concessions
      </p>
      <b-link
        variant="outline-info mx-2"
        type="button"
        @click="onActivityLog"
      >
        Activity log
      </b-link>
    </div>
    <!-- Add button and Session input -->
    <div class="custom-search d-flex justify-content-between">
      <div>
        <b-form-group>
          <b-button
            v-b-modal.student-concession-modal
            variant="outline-primary"
            @click="onCreateConcession"
          >
            Add Concession
          </b-button>
        </b-form-group>
      </div>
      <!-- Head Type -->
      <b-form-group
        label-for="head-type-filter"
        class="pr-0 col-3"
      >
        <v-select
          v-model="secSessionId"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          placeholder="Select Session"
          :options="stdSessions"
          :reduce="(val) => val.id"
        />
      </b-form-group>
    </div>
    <preloader v-if="isLoading" />
    <!-- table -->
    <concession-table
      v-if="!isLoading"
      :rows="rows"
      :total-records="totalRecords"
      :is-loading="isLoading"
      :refresh-grid="refreshGrid"
      :on-approve-row="onApproved"
      :on-decline-row="onDecline"
      :on-details-row="onDetailsRow"
      :on-delete-row="onDeleteRow"
      :concession-filter-options="concessionFilterOptions"
      :on-column-filter-server="onColumnFilterServer"
      :server-params="serverParams"
      :class-list="classList"
      :campus-list="campusList"
      :section-list="sectionList"
    />

    <!-- Audit Activity -->
    <activity-modal
      v-if="activityModal"
      ref="activityModal"
      :tag="tag"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BRow,
  BCol,
  VBModal,
  BButton,
  BLink,
  // BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
import store from '@/store/index'
import AccStudentConcessionService from '@/@service/api/accounting/AccStudentConcession.service'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import ActiveListTable from './components/ActiveListTable.vue'
import ConcessionTable from './components/ConcessionTable.vue'
import ConcessionForm from './components/ConcessionForm.vue'
import Preloader from '../components/preloader/preloader.vue'
import ActivityModal from '../audit/ActivityModal.vue'

export default {
  components: {
    BCard,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ConcessionTable,
    ActiveListTable,
    ConcessionForm,
    Preloader,
    BLink,
    ActivityModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      selectedModal: null,
      pageLength: 10,
      dir: false,
      rows: [],
      totalRecords: 0,
      searchTerm: '',
      stdSessions: [],
      campusList: [],
      classList: [],
      sectionList: [],
      secSessionId: null,
      isLoading: false,
      studentInfo: { full_name: '' },
      concessionTypes: [
        { name: 'Merit Concession', id: '1' },
        { name: 'Applied Concession', id: '2' },
      ],
      concessionFilterOptions: [],
      updateId: null,
      otherConcessionRows: [],
      updateData: {},
      isSubmitting: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },

      tag: 'acc-std-concession',
      activityModal: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    secSessionId(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        this.getStudentConcessionGridData()
      }
    },
  },
  created() {
    this.getSecSessionData()
    this.concessionType()
    this.getClassesData()
    this.getCampusesData()
    this.getSectionsData()
  },
  mounted() {},
  methods: {
    getStudentConcessionGridData() {
      // this.isLoading = true
      if (!this.secSessionId) {
        this.rows = []
        this.TotalRecords = 1
        this.isLoading = false
        return
      }
      this.rows = []
      AccStudentConcessionService.getAll(this.secSessionId, this.serverParams)
        .then(res => {
          const response = res.data
          this.rows = response.data
          this.totalRecords = response.total
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          FemsToastrService.error(error.message)
        })
    },
    getSecSessionData() {
      AccStudentConcessionService.getAllSecSession()
        .then(res => {
          const response = res.data
          if (response.status) {
            this.stdSessions = response.data
            this.secSessionId = this.stdSessions.find(
              x => x.is_current === 1,
            ).id
            // this.getStudentConcessionGridData()
          }
        })
        .catch(error => {
          this.isLoading = false
          FemsToastrService.error(error.message)
        })
    },
    getCampusesData() {
      AccStudentConcessionService.getAllCampus().then(res => {
        const response = res.data
        if (response.status) {
          response.data.forEach(campus => this.campusList.push({
            text: campus.name,
            value: campus.id,
          }))
        }
      })
    },
    getClassesData() {
      AccStudentConcessionService.getAllClass().then(res => {
        const response = res.data
        if (response.status) {
          response.data.forEach(item => this.classList.push({
            text: item.name,
            value: item.id,
          }))
        }
      })
    },
    getSectionsData() {
      AccStudentConcessionService.getAllSection().then(res => {
        const response = res.data
        if (response.status) {
          response.data.forEach(item => this.sectionList.push({
            text: item.name,
            value: item.id,
          }))
        }
      })
    },
    refreshGrid() {
      this.getStudentConcessionGridData()

      this.serverParams = {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      }
      this.isLoading = true
    },
    saveStudentConcession(formData) {
      const params = {
        secAcaSessionId: this.secSessionId,
        studentId: formData.studentId,
        concessionType: formData.concessionType,
        amount: formData.amount,
        details: formData.details,
        campus_id: formData.branchId,
      }
      this.isSubmitting = true
      //  update and create
      if (this.updateId) {
        // this.isLoading = true
        AccStudentConcessionService.update(params, this.updateId)
          .then(res => {
            const response = res.data
            this.isSubmitting = false

            if (response.status) {
              FemsToastrService.success(response.message)
              this.$bvModal.hide('student-concession-modal')
              this.getStudentConcessionGridData()
              return
            }
            this.isLoading = false
            FemsToastrService.error(response.message)
          })
          .catch(error => {
            this.isLoading = false
            this.isSubmitting = false
            FemsToastrService.error(error.message)
          })
      } else {
        this.isLoading = true
        AccStudentConcessionService.create(params)
          .then(res => {
            const response = res.data
            this.isSubmitting = false
            if (response.status) {
              FemsToastrService.success(response.message)
              this.$bvModal.hide('student-concession-modal')
              this.getStudentConcessionGridData()
              return
            }
            this.isLoading = false
            FemsToastrService.error(response.message)
          })
          .catch(error => {
            this.isLoading = false
            this.isSubmitting = false
            FemsToastrService.error(error.message)
          })
      }
    },

    onCreateConcession() {
      this.resetOthers()
      this.$bvModal.show('student-concession-modal')
    },

    concessionType() {
      AccStudentConcessionService.getConcessionTypes().then(res => {
        const response = res.data
        if (response.status) {
          this.concessionTypes = response.data
          this.concessionTypes.forEach(coa => this.concessionFilterOptions.push({
            text: coa.gl_name,
            value: coa.id,
          }))
        } else {
          FemsToastrService.error(response.message)
        }
      })
    },
    onApproved(Id) {
      this.$swal({
        title: 'Are you sure to Approved?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          // eslint-disable-next-line no-empty
          if (result.value) {
            AccStudentConcessionService.approved(Id).then(res => {
              const response = res.data
              if (response.status) {
                this.$swal({
                  icon: 'success',
                  title: 'Approved!',
                  text: response.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getStudentConcessionGridData()
              } else {
                FemsToastrService.error(response.message)
              }
            })
          }
        })
        .catch(() => {
          FemsToastrService.error(
            'Something went wrong! Please contact your system admin!',
          )
        })
    },

    onDecline(Id) {
      this.$swal({
        title: 'Are you sure to Decline?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          // eslint-disable-next-line no-empty
          if (result.value) {
            AccStudentConcessionService.decline(Id).then(res => {
              const response = res.data
              if (response.status) {
                this.$swal({
                  icon: 'success',
                  title: 'Declined!',
                  text: response.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getStudentConcessionGridData()
              } else {
                FemsToastrService.error(response.message)
              }
            })
          }
        })
        .catch(() => {
          FemsToastrService.error(
            'Something went wrong! Please contact your system admin!',
          )
        })
    },

    onDetailsRow(Id) {
      this.resetOthers()
      this.updateId = Id
      this.otherConcessionRows = []

      AccStudentConcessionService.getOne(Id).then(res => {
        const response = res.data
        if (response.status) {
          this.updateData = response.data
          this.studentInfo = response.data
          if (
            response.data.others_concession
            && response.data.others_concession.length
          ) {
            this.otherConcessionRows = response.data.others_concession
          }

          this.$bvModal.show('student-concession-modal')
        } else {
          FemsToastrService.error(response.message)
        }
      })
    },

    onDeleteRow(Id) {
      if (!Id) {
        FemsToastrService.error(
          'Something went wrong! Please contact your system admin!',
        )
      }
      this.$swal({
        title: 'Are you sure to delete?',
        text: "You won't be able to revert this!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          // eslint-disable-next-line no-empty
          if (result.value) {
            AccStudentConcessionService.delete(Id).then(res => {
              const response = res.data
              if (response.status) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: response.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getStudentConcessionGridData()
              } else {
                FemsToastrService.error(response.message)
              }
            })
          }
        })
        .catch(() => {
          FemsToastrService.error(
            'Something went wrong! Please contact your system admin!',
          )
        })
    },
    onStudentOtherConcession(studentData) {
      this.studentInfo = {}
      this.otherConcessionRows = []
      if (!studentData.student_id) return
      this.studentInfo = studentData
      AccStudentConcessionService.getOthersConcession(
        studentData.student_id,
        this.secSessionId,
      ).then(res => {
        const response = res.data
        if (response.status) {
          this.otherConcessionRows = response.data
        }
      })
    },

    onColumnFilterServer(params) {
      this.serverParams = params

      if (JSON.stringify(this.serverParams) === JSON.stringify(params)) {
        this.getStudentConcessionGridData()
      }
    },

    resetOthers() {
      this.updateId = null
      this.updateData = {}
      this.updateData.student_id = null
      this.otherConcessionRows = []
      this.studentInfo = {}
    },

    // Audit Activity
    // Audit Activity
    onActivityLog() {
      this.activityModal = true
      this.$nextTick(() => {
        this.$refs.activityModal.modalShow()
      })
    },
  },
}
</script>
