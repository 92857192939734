import axiosIns from '@/libs/axios'

export default {
  getAll: (id, serverParams) => axiosIns.get(`accounting/acc-student-concession/read/${id}?page=${serverParams.page}&perPage=${serverParams.perPage}&sort=${JSON.stringify(serverParams.sort)}&filter=${JSON.stringify(serverParams.columnFilters)}`),
  create: payload => axiosIns.post('accounting/acc-student-concession', payload),
  getOne: id => axiosIns.get(`accounting/acc-student-concession/${id}`),
  update: (payload, id) => axiosIns.put(`accounting/acc-student-concession/${id}`, payload),
  delete: id => axiosIns.delete(`accounting/acc-student-concession/${id}`),
  approved: id => axiosIns.put(`accounting/acc-student-concession/approved/${id}`),
  decline: id => axiosIns.put(`accounting/acc-student-concession/decline/${id}`),
  getConcessionTypes: () => axiosIns.get('accounting/concession-type'),
  getOthersConcession: (studentId, sessionId) => axiosIns.get(
    `accounting/acc-student-concession/others-concession/${studentId}/${sessionId}`,
  ),
  getConcessionFilter: (sessionId, field, filter) => axiosIns.get(
    `accounting/acc-student-concession/filter/${sessionId}/${field}/${filter}`,
  ),
  getAllSecSession: () => axiosIns.get('accounting/acc-student-concession/sec-sessions'),
  getAllCampus: () => axiosIns.get('accounting/acc-student-concession/campuses'),
  getAllClass: () => axiosIns.get('accounting/acc-student-concession/classes'),
  getAllSection: () => axiosIns.get('accounting/acc-student-concession/sections'),

}
