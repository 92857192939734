<template>
  <!-- table -->
  <vue-good-table
    mode="remote"
    :total-rows="totalRecords"
    :columns="columns"
    :rows="rows"
    :rtl="direction"
    :pagination-options="{
      enabled: true,
      perPage: pageLength,
    }"
    theme="my-theme"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >

      <!-- Column: Common -->
      <span>
        {{ props.formattedRow[props.column.field] }}
      </span>

      <!-- slot: Common -->
      <slot
        :name="props.column.field"
        :row="props.row"
        :column="props.column"
        :index="props.index"
      />

    </template>

    <!--  pagination -->

    <template
      slot="pagination-bottom"
      slot-scope="props"
    >

      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0 mt-1">
          <span class="text-nowrap"> Showing 1 to </span>
          <b-form-select
            v-model="pageLength"
            :options="perPageOptions"
            class="mx-1"
            @input="(value) =>onPerPageChange(value)"
          />
          <span class="text-nowrap"> of {{ props.total }} entries </span>
        </div>
        <div class="d-flex">
          <div class="mr-2">
            <b-pagination
              :value="currentPage"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) =>onPageChange(value)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
          <div
            slot="table-actions"
            class="mt-1"
          >
            <b-button
              v-if="refreshGrid"
              class="btn-sm mr-1 px-1 "
              variant="outline-primary"
              @click="refreshGrid()"
            >
              <RefreshCwIcon />
            </b-button>
          </div>
        </div>
      </div>
    </template>

  </vue-good-table>
</template>

<script>
import {
  BPagination,
  BFormSelect,
  BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import store from '@/store/index'
import { RefreshCwIcon } from 'vue-feather-icons'

export default {
  components: {
    VueGoodTable,
    RefreshCwIcon,
    BPagination,
    BFormSelect,
    BButton,
  },
  props: {
    totalRecords: Number,
    columns: Array,
    rows: Array,
    refreshGrid: Function,
    perPage: Number,
    currentPage: Number,
    onColumnFilterServer: Function,
    serverPropsPerams: Object,
  },
  data() {
    return {
      dir: false,
      serverParams: {
        columnFilters: {},
        sort: {
          field: '',
          type: '',
        },
        page: 1,
        perPage: 10,
      },
      pageLength: this.perPage || 10,
      perPageOptions: ['5', '10', '20', '30', '40', '50'],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },

  },
  watch: {
    serverPropsPerams() {
      this.serverParams = this.serverPropsPerams
    },
  },
  created() {
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = { ...this.serverPropsPerams, ...newProps }
    },

    onPageChange(params) {
      this.updateParams({ page: params })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: Number(params) })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          field: params[0].field,
          type: params[0].type,
        },
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    loadItems() {
      this.onColumnFilterServer(this.serverParams)
    },

  },
}
</script>
<style lang="scss">
svg.feather.feather-refresh-cw {
  height: 15px;
  width:
 15px;
}
</style>
