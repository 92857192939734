<template>
  <b-form @submit.prevent>
    <b-row>
      <b-col cols="12">
        <!-- serch for Student ID  -->
        <b-form-group
          label="Student"
          label-for="student"
          label-cols-md="3"
          :state="studentState"
          invalid-feedback="Student is Required"
        >
          <vue-autosuggest
            v-if="updateData.is_approved===1 ? false : true"
            v-model="concessionFormData.student"
            :state="studentState"
            :suggestions="searchStudents"
            :limit="10"
            :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Search Student ID'}"
            :get-suggestion-value="getSuggestionValueForStudent"
            type="number"
            @selected="onSelectedStudent"
            @input="onInputChangeStudent"
          >
            <template slot-scope="{suggestion}">
              <span class="my-suggestion-item">
                {{ suggestion.item.full_name }} ({{ suggestion.item.student_id }})<br>
                <span class="custom-font-size">
                  Class : {{ suggestion.item.std_academic.student_class.name }}
                  <span v-if="suggestion.item.std_academic.student_section">({{ suggestion.item.std_academic.student_section.name }})</span>
                  <span v-else>(N/A)</span>,
                  Roll : <span v-if="suggestion.item.std_academic.roll_no">{{ suggestion.item.std_academic.roll_no }}</span>
                  <span v-else>(N/A)</span>,
                  {{ suggestion.item.std_academic.branch.name }}
                  ({{ suggestion.item.std_academic.secondary_session.name }})
                </span>
              </span>
            </template>
          </vue-autosuggest>
          <p v-else>
            <b-form-input
              v-model="updateData.student_id"
              :disabled="updateData.is_approved===1 ? true : false"
              type="number"
              placeholder="Student ID"
              :state="studentState"
            />
          </p>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Concession Type"
          label-for="concessionType"
          label-cols-md="3"
          :state="concessionTypeState"
          invalid-feedback="Concession Type is Required"
        >
          <v-select
            v-model="concessionFormData.concessionType"
            :disabled="updateData.is_approved===1 ? true : false"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="gl_name"
            placeholder="Select Concession Type"
            :options="concessionTypes"
            :reduce="val => val.id"
            :state="concessionTypeState"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Amount"
          label-for="amount"
          label-cols-md="3"
          :state="amountState"
          invalid-feedback="Amount is Required"
        >
          <b-form-input
            id="amount"
            v-model="concessionFormData.amount"
            :disabled="updateData.is_approved===1 ? true : false"
            type="number"
            placeholder="Amount"
            :state="amountState"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Note"
          label-for="details"
          label-cols-md="3"
          :state="detailsState"
          invalid-feedback="Note is Required"
        >
          <b-form-textarea
            id="details"
            v-model="concessionFormData.details"
            :disabled="updateData.is_approved===1 ? true : false"
            placeholder="Note "
            rows="3"
            max-rows="6"
            :state="detailsState"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label-for="buttons"
          label-cols-md="3"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :disabled="updateData.is_approved===1 ? true : isSubmitting || isSubmitting"
            type="submit"
            variant="outline-primary"
            class="mr-1"
            @click="onSaveStudentConcession"
          >
            {{ updateId ? "Update" : "Submit" }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            :disabled="updateId ? true : isSubmitting ? true : isSubmitting"
            type="reset"
            variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>

</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormTextarea, BButton,
  // BFormCheckboxGroup, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    // BFormCheckboxGroup,
    // BFormCheckbox,
    vSelect,
    VueAutosuggest,
  },
  directives: {
    Ripple,
  },
  props: {
    concessionTypes: Array,
    isLoading: Boolean,
    updateId: Number,
    saveStudentConcession: Function,
    onStudentOtherConcession: Function,
    updateData: Object,
    isSubmitting: Boolean,
  },
  data() {
    return {
      concessionFormData: {},
      studentInfo: {},
      accountSelected: null,
      paymentModeSelected: null,
      paymentModeOptions: [],

      studentState: null,
      concessionTypeState: null,
      amountState: null,
      continueState: null,
      detailsState: null,
      searchStudents: [],
      is_show: false,
    }
  },
  created() {
    this.resetStudentconcessionForm()
    if (this.updateData?.student_id) {
      this.concessionFormData.student = JSON.stringify(this.updateData.student_id)
      this.concessionFormData.studentId = this.updateData.student_id
      this.concessionFormData.concessionType = this.updateData.concession_type
      this.concessionFormData.amount = this.updateData.amount
      this.concessionFormData.details = this.updateData.details
    }
  },
  methods: {
    validate() {
      this.studentState = null
      this.concessionTypeState = null
      this.amountState = null
      this.detailsState = null
      if (!this.concessionFormData.student) {
        this.studentState = false
      }
      if (!this.concessionFormData.concessionType || (this.concessionFormData.concessionType && this.concessionFormData.concessionType.length < 1)) {
        this.concessionTypeState = false
      }
      if (!this.concessionFormData.amount) {
        this.amountState = false
      }
      if (!this.concessionFormData.details) {
        this.detailsState = false
      }
      if (this.studentState !== false && this.concessionTypeState !== false
        && this.amountState !== false && this.detailsState !== false) {
        return true
      }
      return false
    },

    onSaveStudentConcession() {
      if (!this.validate()) {
        return
      }
      this.saveStudentConcession(this.concessionFormData)
    },
    /*
     * Auto sugession code here for student id search
    */
    getSuggestionValueForStudent(suggestion) {
      return suggestion.item.student_id
    },

    onSelectedStudent(option) {
      this.studentInfo = {}
      this.studentInfo.full_name = option.item.full_name
      this.studentInfo.student_id = option.item.student_id
      this.studentInfo.class_name = option.item.std_academic.student_class.name
      this.studentInfo.section_name = option.item.std_academic.student_section.name
      this.studentInfo.roll = option.item.std_academic.roll_no
      this.studentInfo.campusName = option.item.std_academic.branch.name
      this.concessionFormData.studentId = option.item.student_id || null
      this.concessionFormData.branchId = option.item.std_academic.branch_id || null
      if (option.item.student_id) {
        this.onStudentOtherConcession(this.studentInfo)
      }
    },

    onInputChangeStudent(search) {
      if (!search) {
        this.searchStudents = []
        this.is_show = true
        return
      }

      if (search && search.length > 10) {
        this.idExceed = true
        this.searchStudents = []

        this.is_show = false
        return
      }

      if (search.length && (search.length >= 4 && search.length <= 10)) {
        this.idExceed = false
        const token = localStorage.getItem('accessToken')
        // this.$http.get(`${window.apiUrl}accounting/acc-std-charge-fee/students/${search}`)
        this.$http.get(`${window.apiUrl}accounting/acc-std-charge-fee/students/${search}`, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => {
            if (res.status) {
              if (res.data.status) {
                if (res.data.data < 1) {
                  this.is_show = false
                } else {
                  this.searchStudents = [{
                    data: res.data.data,
                  }]
                }
              } else {
                this.searchStudents = []
              }
            }
          })
          .catch(() => {

          })
      } else {
        this.searchStudents = []
        this.stdFeeConfigSessionOptions = []
        // this.clearFunc()
        this.is_show = false
      }
    },

    resetStudentconcessionForm() {
      this.concessionFormData = {}
      this.studentState = null
      this.concessionTypeState = null
      this.amountState = null
      this.detailsState = null
      // this.continueState = null
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
